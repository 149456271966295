/** @format */

import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import { PropsWithChildren, forwardRef, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

export interface ITText extends PropsWithChildren<TextProps> {
  useI18n?: boolean;
  children?: string | string[];
}

const TTText = forwardRef<HTMLSpanElement, ITText>(function TextRef(
  { useI18n = true, children, className = "", ...props },
  ref
) {
  const { t } = useTranslation();

  const result = useMemo(() => {
    const text = (
      useI18n && children ? t(children as string) : children
    ) as string;
    return text;
  }, [useI18n, children, t]) as string;

  return (
    <Typography.Text
      ref={ref}
      {...props}
      className={`text-default font-14 ${className}`}
    >
      {result}
    </Typography.Text>
  );
});

export const TText = memo(TTText);
