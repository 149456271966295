import { Select as SelectAntd, SelectProps } from "antd";
import { integrateInputClassname } from "components/input";
import { useAsync } from "hooks/useAsync";
import React, { useMemo } from "react";

export const Select: React.FC<SelectProps & { readOnly?: boolean }> = ({
  readOnly,
  ...props
}) => {
  return (
    <SelectAntd
      {...props}
      className={
        "flex items-center " + integrateInputClassname(props.className)
      }
      open={readOnly ? false : undefined}
    />
  );
};


export const SelectApi: React.FC<
SelectProps & {
  readOnly?: boolean;
  asyncFunction: (...args: any[]) => Promise<any>;
  params?: any[];
}
> = ({ readOnly, asyncFunction, params = [], ...props }) => {

const [, list] = useAsync(asyncFunction, {
  callOnFirst: !!asyncFunction,
  callOnFirstArgs: params
});

const listSelections = useMemo(
  () =>
  list.data?.data?.map(({ id, name }: any) => ({
      value: id,
      label: name,
    })),
  [list.data?.data]
);

return (
  <SelectAntd
    {...props}
    className={
      "flex items-center " + integrateInputClassname(props.className)
    }
    open={readOnly ? false : undefined}
    options={listSelections}
  />
);
};