/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Chat } from '../models/Chat';
import type { CreateChatForAdminDto } from '../models/CreateChatForAdminDto';
import type { OrderBy } from '../models/OrderBy';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminChatService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all room chat
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @returns any
     * @throws ApiError
     */
    public chatAdminControllerGetListChat(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/admin',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
            },
        });
    }

    /**
     * Create new message
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public chatAdminControllerCreateForAdmin(
        requestBody: CreateChatForAdminDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/chat/admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all message from a room
     * @param chatId
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @returns any
     * @throws ApiError
     */
    public chatAdminControllerGetDetailChat(
        chatId: number,
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/admin/{chat_id}',
            path: {
                'chat_id': chatId,
            },
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
            },
        });
    }

    /**
     * Receive chat
     * @param chatId
     * @returns Chat
     * @returns any
     * @throws ApiError
     */
    public chatAdminControllerReceiveChat(
        chatId: number,
    ): CancelablePromise<Chat | any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/chat/admin/receive/{chat_id}',
            path: {
                'chat_id': chatId,
            },
        });
    }

}
