/** @format */

import { Breadcrumb } from "antd";
import { TText, Text } from "components";
import { ROUTES } from "constants/routes";
import { memo } from "react";
import { Link, useLocation } from "react-router-dom";

const BreadCrumbs = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/");

  const routes = ROUTES.filter((i) => i.root);

  const extraBreadcrumbItems = pathSnippets
    .filter((i) => !!i)
    .map((i) => {
      const url = `/${i}`;
      return {
        key: url,
        title: (
          <Link to={url}>
            <Text useI18n>{routes.find((i) => url === i.href)?.title}</Text>
          </Link>
        ),
      };
    });

  const breadcrumbItems = [
    {
      title: (
        <Link to="">
          <TText>Trang chủ</TText>
        </Link>
      ),
      key: "home",
    },
  ].concat(extraBreadcrumbItems as any[]);
  return <Breadcrumb items={breadcrumbItems} className="mb-6" />;
};

export default memo(BreadCrumbs);
