import { SVGProps } from "react";

export function UserIcon({
  width = 26,
  height = 24,
  viewBox = "0 0 26 24",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_12_16344)">
        <path
          d="M16.4078 14.2573C15.7543 13.6039 14.9765 13.1201 14.1278 12.8272C15.0367 12.2012 15.6339 11.1535 15.6339 9.96875C15.6339 8.05609 14.0778 6.5 12.1652 6.5C10.2525 6.5 8.69641 8.05609 8.69641 9.96875C8.69641 11.1535 9.29358 12.2012 10.2026 12.8272C9.35388 13.1201 8.57604 13.6039 7.92253 14.2573C6.78928 15.3906 6.16516 16.8973 6.16516 18.5H7.10266C7.10266 15.7085 9.37368 13.4375 12.1652 13.4375C14.9566 13.4375 17.2277 15.7085 17.2277 18.5H18.1652C18.1652 16.8973 17.541 15.3906 16.4078 14.2573ZM12.1652 12.5C10.7694 12.5 9.63391 11.3645 9.63391 9.96875C9.63391 8.573 10.7694 7.4375 12.1652 7.4375C13.5609 7.4375 14.6964 8.573 14.6964 9.96875C14.6964 11.3645 13.5609 12.5 12.1652 12.5Z"
          stroke={stroke}
        />
      </g>
      <circle cx="12.1652" cy="12.5" r="11.5" stroke={stroke} />
      <defs>
        <clipPath id="clip0_12_16344">
          <rect
            width="12"
            height="12"
            fill={stroke}
            transform="translate(6.16516 6.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
