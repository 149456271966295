import { SVGProps } from "react";

export function BellIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.93469 18.25C6.93469 18.75 7.00814 19.5997 7.65962 20.0521C8.3111 20.5046 9.11126 20.75 9.93469 20.75C10.7581 20.75 11.5583 20.5046 12.2098 20.0521C12.8612 19.5997 12.9347 18.75 12.9347 18.25"
        fill={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.18404 8.75C3.18404 5.02208 6.20612 2 9.93404 2V0.5C5.37769 0.5 1.68404 4.19365 1.68404 8.75V11.8574C1.68404 12.5991 1.44348 13.3207 0.998469 13.9141L0.735402 14.2648C0.456038 14.6373 0.0424021 15.2949 0.199052 16.066C0.44867 17.2947 1.51772 17.73 2.40161 17.8891C3.13046 18.0203 3.97678 18.0108 4.65078 18.0032C4.7972 18.0016 4.93552 18 5.0627 18H9.93404V16.5H5.0627C4.89937 16.5 4.73536 16.5016 4.57184 16.5032C3.90251 16.5097 3.24125 16.5161 2.66736 16.4128C1.94992 16.2837 1.72691 16.0523 1.66902 15.7673C1.66136 15.7296 1.6608 15.6656 1.70298 15.5564C1.74679 15.443 1.82496 15.3121 1.9354 15.1648L2.19847 14.8141C2.83821 13.9611 3.18404 12.9236 3.18404 11.8574V8.75Z"
        fill={stroke}
      />
      <path
        d="M16.6848 8.75C16.6848 5.02208 13.662 2 9.93404 2V0.5C14.4904 0.5 18.1848 4.19365 18.1848 8.75V11.8574C18.1848 12.5991 18.4254 13.3207 18.8704 13.9141L19.1335 14.2648C19.4128 14.6373 19.8265 15.2949 19.6698 16.066C19.4202 17.2947 18.3512 17.73 17.4673 17.8891C16.7384 18.0203 15.8921 18.0108 15.2181 18.0032C15.0717 18.0016 14.9334 18 14.8062 18H9.93404V16.5H14.8062C14.9695 16.5 15.1335 16.5016 15.297 16.5032C15.9664 16.5097 16.6276 16.5161 17.2015 16.4128C17.919 16.2837 18.142 16.0523 18.1999 15.7673C18.2075 15.7296 18.2081 15.6656 18.1659 15.5564C18.1221 15.443 18.0439 15.3121 17.9335 15.1648L17.6704 14.8141C17.0307 13.9611 16.6848 12.9236 16.6848 11.8574V8.75Z"
        fill={stroke}
      />
    </svg>
  );
}
