/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserDto = {
    phone_number?: string;
    password: string;
    full_name: string;
    hometown?: string;
    birthday?: string;
    gender?: UpdateUserDto.gender;
    address?: string;
    images?: Array<string>;
    group_id?: number;
    salary: number;
    is_accept?: boolean;
    bank_name?: string;
    bank_number?: string;
};

export namespace UpdateUserDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }


}

