/** @format */

import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Badge, Col, Dropdown, Image, Layout, MenuProps, Row } from "antd";
import * as Icons from "assets/icons";
import logo from "assets/images/logo.png";
import { Text } from "components";
import i18n from "i18n";
import { memo, useCallback, useMemo } from "react";
import "./style.css";
import { authStorage } from "../../../helpers/localStorage";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/routes";
import { AuthAction, useAuth } from "../../../providers/auth";

enum EnumHeaderActions {
  account = "account",
  logout = "logout",
  language = "language",
}

const Header = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const onLogout = useCallback(() => {
    console.log("out");
    dispatch({
      type: AuthAction.clearAuth,
      payload: undefined,
    });
    authStorage.set("token", null);
    navigate(PATHS.login, { replace: true });
  }, [dispatch, navigate]);

  const handleMenuClick = useCallback(
    (action: EnumHeaderActions, payload?: any) => {
      async function asyncFunc(value: string) {
        console.log("action", action);
        if (action === EnumHeaderActions.logout) {
          onLogout();
          return;
        }
        if (action === EnumHeaderActions.language) {
          // await i18n.changeLanguage(value);
          return;
        }
      }
      console.log("mb");
      asyncFunc(payload).then();
    },
    [onLogout]
  );
  const menuLanaguage = useMemo(
    () => [
      // {
      //   label: <Text useI18n>lbl_language_en</Text>,
      //   key: "2.1",
      // },
      {
        label: (
          <Text
            onClick={() => handleMenuClick(EnumHeaderActions.language, "vi")}
            useI18n
          >
            lbl_language_vi
          </Text>
        ),
        key: "2.2",
      },
    ],
    [handleMenuClick]
  );
  const profileMenu: MenuProps["items"] = useMemo(
    () => [
      // {
      //   label: (
      //     <Text onClick={handleMenuClick(EnumHeaderActions.account)} useI18n>
      //       lbl_account
      //     </Text>
      //   ),
      //   key: '1',
      //   icon: <UserOutlined />,
      // },
      {
        label: (
          <Text
            onClick={() => handleMenuClick(EnumHeaderActions.logout)}
            useI18n
          >
            lbl_logout
          </Text>
        ),
        key: "3",
        icon: <LogoutOutlined />,
      },
    ],
    [handleMenuClick]
  );

  return (
    <Layout.Header className="header-base bg-white">
      <Row justify={"space-between"} wrap={false} className="f-width f-height">
        <Col className="wrap-header-logo">
          <Image
            src={logo}
            preview={false}
            height={35}
            className="header-logo"
          />
        </Col>
        <Row justify={"center"} align={"middle"} className="gap-6">
          <Badge dot className="cursor-pointer">
            <Icons.BellIcon
              className="bell-icon"
              stroke="var(--header-bg-color)"
            />
          </Badge>
          <Dropdown menu={{ items: menuLanaguage }} placement={"bottomRight"}>
            <Row
              align={"middle"}
              justify={"center"}
              wrap={false}
              className="row-custom lang-box"
              style={{ gridGap: 8 }}
            >
              <Text className="font-14 text-left font-12" useI18n>
                {`lbl_language_${i18n.language || "vi"}`}
              </Text>
              <DownOutlined className="ml-1 font-12 text-left text-white " />
              {/* <Text className=' text-left'>{userProfile?.data?.roles?.[0]?.name}</Text> */}
            </Row>
          </Dropdown>

          <Dropdown menu={{ items: profileMenu }} placement={"bottomRight"}>
            <Row align={"middle"} style={{ gridGap: 8 }} className="user-box">
              <Icons.UserIcon stroke="var(--header-bg-color)" />
              <Row justify={"center"} align={"middle"}>
                <DownOutlined className="ml-1 font-12 text-left text-white" />
              </Row>
            </Row>
          </Dropdown>
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default memo(Header);
