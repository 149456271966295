/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductDto } from '../models/CreateProductDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateProductDto } from '../models/UpdateProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminProductService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create product
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerCreate(
        requestBody: CreateProductDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all product
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param countryId
     * @param regionId
     * @param sectorId
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        countryId?: string,
        regionId?: string,
        sectorId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/product',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'country_id': countryId,
                'region_id': regionId,
                'sector_id': sectorId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerImportExcel(): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/product/excel',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerExportExcel(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/product/excel',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerTemplateImportExcel(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/product/excel/template',
        });
    }

    /**
     * Get detail product
     * @param id
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/product/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update product
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerUpdate(
        id: number,
        requestBody: UpdateProductDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/product/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete product
     * @param id
     * @returns any
     * @throws ApiError
     */
    public productAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/product/{id}',
            path: {
                'id': id,
            },
        });
    }

}
