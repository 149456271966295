/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePartnerDto = {
    name?: string;
    password?: string;
    hometown?: string;
    birthday?: string;
    gender?: UpdatePartnerDto.gender;
    address?: string;
    images?: Array<string>;
    group_id?: number;
    priority?: UpdatePartnerDto.priority;
    fee?: number;
    note?: string;
    bank_name?: string;
    bank_number?: string;
};

export namespace UpdatePartnerDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }

    export enum priority {
        LOW = 'low',
        HIGHT = 'hight',
    }


}

