import http from "../http";
import { ICadastral, IParamsCadastral, IUpload } from "./types";

export const apiUtilsCadastral = (params?: IParamsCadastral) =>
  http.get<BaseAPIResponse<Array<ICadastral>>>("/api/utils/cadastral_vn", {
    params,
  });

export const apiUtilsUploadSingle = (body: File) => {
  const formData = new FormData();
  formData.append("file", body);
  return http.post<BaseAPIResponse<string>>("/api/utils/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const apiUtilsMultiple = (body: Array<File>) => {
  const formData = new FormData();
  body.forEach((x) => {
    formData.append(`files`, x);
  });

  return http.post<BaseAPIResponse<{ result: IUpload[] }>>(
    "/upload_multiple",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
