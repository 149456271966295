/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateStaffDto = {
    name: string;
    username: string;
    password: string;
    hometown?: string;
    birthday?: string;
    gender?: CreateStaffDto.gender;
    address?: string;
    images?: Array<string>;
    region_id: number;
    salary: number;
    commission: number;
    phone_number: string;
    bank_name?: string;
    bank_number?: string;
};

export namespace CreateStaffDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }


}

