import { CHECK_PERMISSION } from "constants/utils";
import { useAuth } from "providers/auth";
import { useMemo } from "react";

const useCheckPermission = (roles?: string | Array<string>) => {
  const {
    state: { user },
  } = useAuth();

  const listRoles = useMemo(() => {
    if (user?.roles && user?.roles?.length > 0) {
      return user?.roles[0].permissions.flatMap((x: any) => x);
    }
    return [];
  }, [user?.roles]);

  return useMemo(
    () => ({
      fnCheck: (_roles?: string | Array<string>) =>
        CHECK_PERMISSION(_roles, listRoles),
      check: CHECK_PERMISSION(roles, listRoles),
    }),
    [listRoles, roles]
  );
};

export default useCheckPermission;
