import * as Icons from "assets/icons";

export enum SOURCE_ROUTES {
  ADVANCED_SETTINGS = "/advanced-settings",
  COUNTRY = "/country",
  REGION = "/region",
  DRIVER = "/driver",
  FIELD_GROUP = "/field_group",
  PRODUCTS = "/products",
  FEE_MANAGE = "/fee_management",
  ORDER = "/order",
  PARTNER = "/partner",
  STAFF = "/staff",
  REVENUE = "/revenue",
  RECEIPT = "/receipt",
  PAYMENT = "/payment",
}

export const PATHS = {
  home: "/advanced-settings",
  login: "/login",
  advancedSettings: SOURCE_ROUTES.ADVANCED_SETTINGS,
  country: SOURCE_ROUTES.COUNTRY,
  countryDetail(id: string) {
    return `${this.country}/${id}`;
  },
  region: SOURCE_ROUTES.REGION,
  regionDetail(id: string) {
    return `${this.region}/${id}`;
  },
  driver: SOURCE_ROUTES.DRIVER,
  driverDetail(id: string) {
    return `${this.driver}/driver/${id}`;
  },
  driverGroupDetail(id: string) {
    return `${this.driver}/driver-group/${id}`;
  },
  fieldGroup: SOURCE_ROUTES.FIELD_GROUP,
  fieldGroupDetail(id: string) {
    return `${this.fieldGroup}/${id}`;
  },
  product: SOURCE_ROUTES.PRODUCTS,
  productDetail(id: string) {
    return `${this.product}/${id}`;
  },
  feeManagement: SOURCE_ROUTES.FEE_MANAGE,
  feeManagementDetail(id: string) {
    return `${this.feeManagement}/${id}`;
  },
  order: SOURCE_ROUTES.ORDER,
  orderDetail(id: string) {
    return `${this.order}/${id}`;
  },
  partner: SOURCE_ROUTES.PARTNER,
  partnerDetail(id: string) {
    return `${this.partner}/${id}`;
  },
  partnerGroupDetail(id: string) {
    return `${this.partner}/partner-group/${id}`;
  },
  staff: SOURCE_ROUTES.STAFF,
  staffDetail(id: string) {
    return `${this.staff}/${id}`;
  },
  revenue: SOURCE_ROUTES.REVENUE,
  revenueDetail(id: string) {
    return `${this.revenue}/${id}`;
  },
  receipt: SOURCE_ROUTES.RECEIPT,
  receiptDetail(id: string) {
    return `${this.receipt}/${id}`;
  },
  payment: SOURCE_ROUTES.PAYMENT,
  paymentDetail(id: string) {
    return `${this.payment}/${id}`;
  },
};

export const ROUTES = [
  {
    title: "Cài đặt nâng cao",
    href: PATHS.advancedSettings,
    icon: Icons.SettingIcon,
    page: "advanced-settings/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Quốc gia",
    href: PATHS.country,
    icon: Icons.MarkerEarthIcon,
    page: "country/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết quốc gia",
    href: PATHS.countryDetail(":id"),
    icon: Icons.MarkerEarthIcon,
    page: "country/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Khu vực",
    href: PATHS.region,
    icon: Icons.AreaIcon,
    page: "region/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết khu vực",
    href: PATHS.regionDetail(":id"),
    icon: Icons.MarkerEarthIcon,
    page: "region/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Tài xế",
    href: PATHS.driver,
    icon: Icons.DriverIcon,
    page: "driver/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết tài xế",
    href: PATHS.driverDetail(":id"),
    icon: Icons.DriverIcon,
    page: "driver/detail-driver",
    role: "SUPER_ADMIN",
  },
  {
    title: "Chi tiết nhóm tài xế",
    href: PATHS.driverGroupDetail(":id"),
    icon: Icons.DriverIcon,
    page: "driver/detail-driver-group",
    role: "SUPER_ADMIN",
  },
  {
    title: "Nhóm lĩnh vực",
    href: PATHS.fieldGroup,
    icon: Icons.MenuIcon,
    page: "field-group/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết nhóm lĩnh vực",
    href: PATHS.fieldGroupDetail(":id"),
    icon: Icons.MenuIcon,
    page: "field-group/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Sản phẩm",
    href: PATHS.product,
    icon: Icons.MenuIcon,
    page: "product/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết sản phẩm",
    href: PATHS.productDetail(":id"),
    icon: Icons.BillIcon,
    page: "product/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Quản lí phí",
    href: PATHS.feeManagement,
    icon: Icons.FeeManagementIcon,
    page: "fee_management/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết phí",
    href: PATHS.feeManagementDetail(":id"),
    icon: Icons.ExpressIcon,
    page: "fee_management/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Đơn hàng",
    href: PATHS.order,
    icon: Icons.BookCalendarIcon,
    page: "order/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết đơn hàng",
    href: PATHS.orderDetail(":id"),
    icon: Icons.BookCalendarIcon,
    page: "order/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Đối tác",
    href: PATHS.partner,
    icon: Icons.GroupPeopleIcon,
    page: "partner/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết đối tác",
    href: PATHS.partnerDetail(":id"),
    icon: Icons.GroupPeopleIcon,
    page: "partner/detail-partner",
    role: "SUPER_ADMIN",
  },
  {
    title: "Chi tiết nhóm đối tác",
    href: PATHS.partnerGroupDetail(":id"),
    icon: Icons.GroupPeopleIcon,
    page: "partner/detail-partner-group",
    role: "SUPER_ADMIN",
  },
  {
    title: "Nhân viên",
    href: PATHS.staff,
    icon: Icons.PeopleIcon,
    page: "staff/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Chi tiết nhân viên",
    href: PATHS.staffDetail(":id"),
    icon: Icons.PeopleIcon,
    page: "staff/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Doanh thu",
    href: PATHS.revenue,
    icon: Icons.ChartIcon,
    page: "revenue/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Phiếu thu",
    href: PATHS.receipt,
    icon: Icons.FeeIcon,
    page: "receipt/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Quản lý khoản thu",
    href: PATHS.receiptDetail(":id"),
    icon: Icons.Payment,
    page: "receipt/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Phiếu chi",
    href: PATHS.payment,
    icon: Icons.Payment,
    page: "payment/list",
    role: "SUPER_ADMIN",
    root: true,
  },
  {
    title: "Quản lý khoản chi",
    href: PATHS.paymentDetail(":id"),
    icon: Icons.Payment,
    page: "payment/detail",
    role: "SUPER_ADMIN",
  },
  {
    title: "Chi tiết doanh thu",
    href: PATHS.revenueDetail(":id"),
    icon: Icons.ChartIcon,
    page: "revenue/detail",
    role: "SUPER_ADMIN",
  },
];
