import { SVGProps } from "react";

export function Payment({
  width = 24,
  height = 12,
  viewBox = "0 0 24 12",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_3021_13177)">
        <path
          d="M11.1893 8.85185C8.51573 8.85185 5.82917 8.85185 3.15558 8.85185C3.02579 8.85185 2.89601 8.86483 2.7792 8.85185C2.48069 8.82589 2.38984 8.73504 2.37686 8.43653C2.36388 8.34568 2.37686 8.24186 2.37686 8.151C2.37686 5.82784 2.37686 3.49169 2.37686 1.16853C2.37686 1.07768 2.37686 0.973847 2.37686 0.882997C2.40282 0.58449 2.48069 0.493639 2.79218 0.467682C2.89601 0.454704 3.01281 0.467682 3.12962 0.467682C8.51573 0.467682 13.9018 0.467682 19.288 0.467682C19.3399 0.467682 19.3788 0.467682 19.4307 0.467682C19.9628 0.480661 20.0277 0.545554 20.0277 1.07768C20.0277 3.46574 20.0277 5.86677 20.0277 8.25483C20.0277 8.79993 19.9758 8.85185 19.4307 8.85185C16.6663 8.85185 13.9278 8.85185 11.1893 8.85185ZM4.84279 8.18994C9.11275 8.18994 13.3178 8.18994 17.5488 8.18994C17.9382 7.28144 18.4833 6.50272 19.3529 5.99656C19.3529 5.10104 19.3529 4.21849 19.3529 3.32297C18.6261 2.89468 18.0939 2.29766 17.7565 1.5449C17.6137 1.22044 17.445 1.10363 17.0946 1.10363C13.1621 1.12959 9.22956 1.11661 5.29704 1.11661C5.1413 1.11661 4.98556 1.12959 4.81684 1.14257C4.58322 2.11596 3.97323 2.71298 3.02579 2.94659C3.02579 4.10169 3.02579 5.23082 3.02579 6.38592C4.01216 6.61953 4.5962 7.22953 4.84279 8.18994Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M1.84484 1.8822C1.84484 2.07688 1.84484 2.2456 1.84484 2.41432C1.84484 4.37409 1.84484 6.34683 1.84484 8.3066C1.84484 8.56617 1.84484 8.81276 2.02654 9.02042C2.22122 9.25404 2.46782 9.35786 2.7793 9.35786C3.11675 9.37084 3.46717 9.27999 3.77865 9.51361C3.85652 9.56552 3.99929 9.53957 4.10312 9.53957C8.04861 9.53957 11.9941 9.53957 15.9396 9.53957C16.1213 9.53957 16.29 9.46169 16.4717 9.42276C16.5496 9.40978 16.6275 9.37084 16.7053 9.37084C17.4062 9.37084 18.094 9.37084 18.8338 9.37084C18.8468 9.53956 18.8727 9.66935 18.8598 9.81212C18.8468 10.0457 18.717 10.1885 18.4704 10.2015C18.3536 10.2015 18.2498 10.2015 18.133 10.2015C12.7209 10.2015 7.32181 10.2015 1.92272 10.2015C1.83187 10.2015 1.72804 10.2015 1.63719 10.2015C1.29974 10.1755 1.22187 10.0976 1.20889 9.77318C1.19591 9.42276 1.20889 9.07234 1.20889 8.72191C1.20889 6.71023 1.20889 4.69855 1.20889 2.68687C1.20889 2.57007 1.20889 2.46624 1.20889 2.34943C1.20889 1.86922 1.26081 1.83029 1.84484 1.8822Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M17.6119 10.7209C17.6249 10.8377 17.6379 10.9156 17.6509 10.9934C17.6639 11.4736 17.599 11.5385 17.1058 11.5385C11.5899 11.5385 6.07398 11.5385 0.571058 11.5385C0.103829 11.5385 0.0129786 11.4607 0 10.9934C0 8.56643 0 6.15241 0 3.72542C0 3.24521 0.0648929 3.19329 0.622972 3.20627C0.635951 3.38797 0.648929 3.56967 0.648929 3.75137C0.648929 5.60731 0.648929 7.46325 0.648929 9.30621C0.648929 9.43599 0.648929 9.56578 0.648929 9.68259C0.635951 10.3834 1.06424 10.7598 1.71317 10.7079C1.9987 10.6819 2.31019 10.63 2.56976 10.8636C2.63465 10.9156 2.77742 10.8896 2.89422 10.8896C6.86567 10.8896 10.8501 10.8896 14.8215 10.8896C14.9903 10.8896 15.172 10.9285 15.3018 10.7598C15.3277 10.7339 15.3926 10.7209 15.4315 10.7209C16.1583 10.7209 16.8851 10.7209 17.6119 10.7209Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M11.1603 7.44993C9.61588 7.43695 8.39589 6.21697 8.38291 4.67252C8.36993 3.11509 9.64183 1.86914 11.1993 1.86914C12.7307 1.86914 13.9767 3.11508 13.9767 4.65954C13.9767 6.21697 12.7307 7.46291 11.1603 7.44993ZM11.355 3.62125C11.5367 3.66019 11.6535 3.67316 11.7703 3.7121C11.978 3.76401 12.1597 3.69912 12.2246 3.49146C12.3024 3.25785 12.1467 3.12806 11.965 3.05019C11.7703 2.97232 11.5756 2.93338 11.3939 2.86849C11.2771 2.77764 11.381 2.51807 11.1084 2.53105C11.0565 2.64786 11.0046 2.76466 10.9656 2.88147C10.2259 3.10211 9.9663 3.3487 9.94034 3.85486C9.90141 4.56869 10.4335 4.77634 10.9916 4.97102C10.9916 5.23059 10.9916 5.46421 10.9916 5.74974C10.771 5.68484 10.5893 5.61995 10.4076 5.58102C10.2129 5.54208 10.0571 5.61995 9.97928 5.80165C9.88843 6.00931 10.0052 6.15207 10.174 6.22995C10.4335 6.34675 10.7061 6.41165 10.9786 6.5025C11.0305 6.59335 11.0825 6.71015 11.1474 6.82696C11.2642 6.41165 11.6405 6.39867 11.9131 6.2559C12.2765 6.04824 12.4452 5.72378 12.4322 5.30847C12.4193 4.91911 12.1856 4.67252 11.8482 4.51677C11.7573 4.47784 11.6795 4.4389 11.5886 4.41294C11.4069 4.36103 11.3291 4.24422 11.342 4.06252C11.342 3.93274 11.355 3.80295 11.355 3.62125Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M11.3828 5.65928C11.3828 5.4646 11.3828 5.30886 11.3828 5.14014C11.7073 5.33482 11.7203 5.39971 11.3828 5.65928Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M10.9933 3.66064C10.9933 3.82937 10.9933 3.95915 10.9933 4.1149C10.6689 3.93319 10.6689 3.88128 10.9933 3.66064Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_3021_13177">
          <rect
            width="20"
            height="11.0707"
            fill="white"
            transform="translate(0 0.467773)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
