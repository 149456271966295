/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatePartnerDto = {
    name: string;
    username: string;
    password: string;
    hometown?: string;
    birthday?: string;
    gender?: CreatePartnerDto.gender;
    address?: string;
    images?: Array<string>;
    group_id: number;
    priority: CreatePartnerDto.priority;
    fee: number;
    note?: string;
    bank_name?: string;
    bank_number?: string;
};

export namespace CreatePartnerDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }

    export enum priority {
        LOW = 'low',
        HIGHT = 'hight',
    }


}

