import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Collapse, Form, Row, SelectProps } from "antd";
import { Button, Input, Select } from "components";
import { Flex } from "components/flex";
import useSearch from "hooks/useSearch";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IBaseFilter {
  label: string;
  name: string;
}

interface IFilter extends IBaseFilter {
  defaultValue?: string;
  options?: SelectProps["options"];
  span?: number;
  isSelect?: boolean;
  placeholder?: string;
  isCustomized?: boolean;
  Customized?: JSX.Element;
}

interface ISearch extends IBaseFilter {
  placeholder?: string;
}

interface ISort extends IBaseFilter {
  defaultValue?: string;
}

interface IFilterProps {
  filters?: IFilter[];
  search?: ISearch;
  sorts?: ISort[];
  openFilters?: boolean;
  openSorts?: boolean;
  onSubmit?: (data: any) => void;
}

export const Filter: React.FC<IFilterProps> = ({
  onSubmit,
  openFilters,
  openSorts,
  filters,
  search,
  sorts,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setFilter, resetFilter, params, search_type, key_words } =
    useSearch();

  const defaultActiveKey = useMemo(() => {
    const result = [];
    if (openFilters) result.push("1");
    if (openSorts) result.push("2");
    return result;
  }, [openFilters, openSorts]);

  useEffect(() => {
    form.setFieldsValue({ ...params, search_type, key_words });
  }, [params, filters, form, search_type, key_words]);

  const FilterHeader = () => (
    <Flex className="items-center">
      <FilterOutlined className="mr-4" />
      {t("Bộ lọc")}
    </Flex>
  );
  return (
    <>
      <Form form={form} name="basic" onFinish={setFilter} autoComplete="off">
        {/* {search && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12} md={12}>
                <Form.Item
                  label={search.label}
                  name={search.name}
                  style={{ marginBottom: 0 }}
                >
                  <Input addonAfter={<SearchOutlined />} defaultValue="" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )} */}

        {(filters?.length || sorts?.length) && (
          <Collapse defaultActiveKey={defaultActiveKey} className="my-4">
            {filters && (
              <Collapse.Panel header={<FilterHeader />} key="1">
                {/* Header */}
                <Flex className="mb-2 justify-between">
                  <FilterHeader />
                  <Flex className="gap-2">
                    <Button
                      htmlType="button"
                      onClick={() => {
                        form.resetFields();
                        resetFilter();
                      }}
                    >
                      {t("Đặt lại")}
                    </Button>
                    <Button
                      htmlType="submit"
                      className="border-red-500 text-red-500"
                      icon={<PlusOutlined />}
                    >
                      {t("Tìm kiếm")}
                    </Button>
                  </Flex>
                </Flex>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={[16, 16]} wrap>
                      {filters?.map((filter) => {
                        const {
                          isCustomized,
                          span = 12,
                          label,
                          isSelect = true,
                        } = filter;
                        if (isCustomized) return filter.Customized;
                        return (
                          <Col key={filter.name} span={span}>
                            <Form.Item
                              // label={filter.label}
                              name={filter.name}
                              className="mb-0"
                            >
                              {isSelect ? (
                                <Select
                                  allowClear
                                  placeholder={label}
                                  options={filter.options}
                                />
                              ) : (
                                <Input placeholder={label} allowClear/>
                              )}
                            </Form.Item>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Collapse.Panel>
            )}
            {/* {sorts && (
              <Collapse.Panel
                header={
                  <Flex className="items-center">
                    {t("Sắp xếp")}
                    <SortAscendingOutlined className="ml-4" />
                  </Flex>
                }
                key="2"
              >
                <Col span={24}>
                  <Row gutter={[16, 16]} wrap>
                    {sorts?.map((sort) => (
                      <Col span={12}>
                        <Form.Item
                          label={`${sort.label}:`}
                          name={sort.name}
                          className="mb-0"
                        >
                          <Select
                            defaultValue={sort.defaultValue}
                            allowClear
                            options={[
                              { label: "Giảm dần", value: "ASC" },
                              { label: "Tăng dần", value: "DESC" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Collapse.Panel>
            )} */}
          </Collapse>
        )}
      </Form>
    </>
  );
};
