/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCountryDto } from '../models/CreateCountryDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateCountryDto } from '../models/UpdateCountryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminCountryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create country
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerCreate(
        requestBody: CreateCountryDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/country',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all country
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param code
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        code?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/country',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'code': code,
            },
        });
    }

    /**
     * Get search country
     * @param search
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerSearch(
        search: string,
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/country/search',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'search': search,
            },
        });
    }

    /**
     * Get detail country
     * @param id
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/country/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update country
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerUpdate(
        id: number,
        requestBody: UpdateCountryDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/country/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete country
     * @param id
     * @returns any
     * @throws ApiError
     */
    public countryAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/country/{id}',
            path: {
                'id': id,
            },
        });
    }

}
