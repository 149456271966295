/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderDto } from '../models/CreateOrderDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateOrderDto } from '../models/UpdateOrderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminOrderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create order
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public orderAdminControllerCreate(
        requestBody: CreateOrderDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/order',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all order
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param customerName
     * @param customerPhone
     * @param address
     * @param countryId
     * @param regionId
     * @param sectorId
     * @param productId
     * @param feeFormId
     * @param status
     * @param typeFeePayer
     * @returns any
     * @throws ApiError
     */
    public orderAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        customerName?: string,
        customerPhone?: string,
        address?: string,
        countryId?: string,
        regionId?: string,
        sectorId?: string,
        productId?: string,
        feeFormId?: string,
        status?: 'pending' | 'transporting' | 'shipped',
        typeFeePayer?: 'shop' | 'customer',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/order',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'customer_name': customerName,
                'customer_phone': customerPhone,
                'address': address,
                'country_id': countryId,
                'region_id': regionId,
                'sector_id': sectorId,
                'product_id': productId,
                'fee_form_id': feeFormId,
                'status': status,
                'type_fee_payer': typeFeePayer,
            },
        });
    }

    /**
     * Get detail order
     * @param id
     * @returns any
     * @throws ApiError
     */
    public orderAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/order/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update order
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public orderAdminControllerUpdate(
        id: number,
        requestBody: UpdateOrderDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/order/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete order
     * @param id
     * @returns any
     * @throws ApiError
     */
    public orderAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/order/{id}',
            path: {
                'id': id,
            },
        });
    }

}
