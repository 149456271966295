/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateUserDto } from '../models/UpdateUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminUserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all user
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param fullName
     * @param phoneNumber
     * @param username
     * @param countryId
     * @param regionId
     * @param groupId
     * @returns any
     * @throws ApiError
     */
    public userAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        fullName?: string,
        phoneNumber?: string,
        username?: string,
        countryId?: number,
        regionId?: number,
        groupId?: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/user',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'full_name': fullName,
                'phone_number': phoneNumber,
                'username': username,
                'country_id': countryId,
                'region_id': regionId,
                'group_id': groupId,
            },
        });
    }

    /**
     * Create user
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public userAdminControllerCreate(
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update user
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public userAdminControllerUpdate(
        id: number,
        requestBody: UpdateUserDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public userAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get detail user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public userAdminControllerGetUserForAdmin(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/user/{id}',
            path: {
                'id': id,
            },
        });
    }

}
