/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAdminDto } from '../models/CreateAdminDto';
import type { CreatePartnerDto } from '../models/CreatePartnerDto';
import type { CreateStaffDto } from '../models/CreateStaffDto';
import type { LoginAdminDto } from '../models/LoginAdminDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateAdminDto } from '../models/UpdateAdminDto';
import type { UpdatePartnerDto } from '../models/UpdatePartnerDto';
import type { UpdateStaffDto } from '../models/UpdateStaffDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Login user admin
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerLogin(
        requestBody: LoginAdminDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Logout user admin
     * @returns any
     * @throws ApiError
     */
    public adminControllerLogout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/logout',
        });
    }

    /**
     * Refresh token user admin
     * @returns any
     * @throws ApiError
     */
    public adminControllerRefreshToken(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/refresh_token',
        });
    }

    /**
     * Create admin
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerCreateAdmin(
        requestBody: CreateAdminDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get list user admin
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param username
     * @returns any
     * @throws ApiError
     */
    public adminControllerGetAllAdmins(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        username?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'username': username,
            },
        });
    }

    /**
     * Create staff
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerCreateStaff(
        requestBody: CreateStaffDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/staff',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get list staff
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param countryId
     * @param regionId
     * @param phoneNumber
     * @returns any
     * @throws ApiError
     */
    public adminControllerGetAllStaffs(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        countryId?: string,
        regionId?: string,
        phoneNumber?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/staff',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'country_id': countryId,
                'region_id': regionId,
                'phone_number': phoneNumber,
            },
        });
    }

    /**
     * Create partner
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerCreatePartner(
        requestBody: CreatePartnerDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/partner',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get list partner
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param countryId
     * @param regionId
     * @param sectorId
     * @param groupId
     * @returns any
     * @throws ApiError
     */
    public adminControllerGetAllPartners(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        countryId?: string,
        regionId?: string,
        sectorId?: string,
        groupId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/partner',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'country_id': countryId,
                'region_id': regionId,
                'sector_id': sectorId,
                'group_id': groupId,
            },
        });
    }

    /**
     * Get detail admin
     * @returns any
     * @throws ApiError
     */
    public adminControllerGetMe(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/me',
        });
    }

    /**
     * Get detail admin
     * @param adminId
     * @returns any
     * @throws ApiError
     */
    public adminControllerGetDetail(
        adminId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/detail/{admin_id}',
            path: {
                'admin_id': adminId,
            },
        });
    }

    /**
     * Update admin
     * @param adminId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerUpdate(
        adminId: number,
        requestBody: UpdateAdminDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/{admin_id}',
            path: {
                'admin_id': adminId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete user admin
     * @param adminId
     * @returns any
     * @throws ApiError
     */
    public adminControllerDelete(
        adminId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/{admin_id}',
            path: {
                'admin_id': adminId,
            },
        });
    }

    /**
     * Update staff
     * @param adminId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerUpdateStaff(
        adminId: number,
        requestBody: UpdateStaffDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/{admin_id}/staff',
            path: {
                'admin_id': adminId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update partner
     * @param adminId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public adminControllerUpdatePartner(
        adminId: number,
        requestBody: UpdatePartnerDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/{admin_id}/partner',
            path: {
                'admin_id': adminId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
