/**
 * @format
 * @description define Vietnames language resource
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  txt: {
    manage_resident: "Quản lý cư dân",
    resident: "{{0}} Cư dân",
    file_export: "Tải xuống",
    file_import: "Tải lên",
    add: "Thêm mới",
    search: "Tìm kiếm",
    filter: "Lọc",
  },
  lable: {
    title_login: "Login",
  },
  placeholder: {
    search: "Tìm kiếm",
  },
  msg_confirm_delete_record: "Xác nhận xóa {record} ?",
  msg_success01: "Thành công",
  msg_name_required: "Tên không được bỏ trống!",
  msg_must_is_number: "Dịnh dạng số",

  lbl_username: "Tên tài khoản",
  lbl_fullname: "Họ và tên",
  lbl_email: "Email",
  lbl_account_bank: "Tài khoản ngân hàng",
  lbl_birthday: "Ngày sinh",
  lbl_address: "Địa chỉ",
  lbl_group_driver: "Nhóm tài xế",
  lbl_city_name: "Tên thành phố",
  lbl_image: "Hình ảnh",
  lbl_amount: "Giá trị",
  lbl_confirm: "Xác nhận",
  lbl_deposit: "Nạp tiền",
  btn_with_draw: "Rút tiền",
  lbl_add_driver: "Thêm nhóm tài xế",
  lbl_add_driver_detail: "Thêm khu vực nhóm tài xế",
  lbl_edit_driver: "Sửa nhóm tài xế",
  lbl_edit_driver_detail: "Sửa khu vực nhóm tài xế",
  lbl_name: "Tên",
  lbl_sub_name: "Tên khác",
  lbl_avatar: "Ảnh đại diện",
  lbl_identification_front: "Mặt trước CCCD/CMND",
  lbl_identification_back: "Mặt sau CCCD/CMND",
  lbl_license_front: "Mặt trước bằng lái",
  lbl_license_back: "Mặt sau bằng lái",
  lbl_user_name: "Tên đăng nhập",
  lbl_full_name: "Họ và tên",
  lbl_phone_number: "Số điện thoại",
  lbl_password: "Mật khẩu",
  lbl_confirm_password: "xác nhận mật khẩu",
  lbl_edit_user: "Chỉnh sửa người dùng",
  lbl_add_user: "Thêm mới người dùng",
  lbl_title: "Tiêu đề",
  lbl_content: "Nội dung",
  lbl_pending: "Đang chờ",
  lbl_success: "Thành công",
  lbl_canceled: "Đã hủy",
  lbl_transaction: "Giao dịch",
  lbl_list_user: "Danh sách người dùng",
  lbl_group_driver_detail: "Nhóm tài xế theo khu vực",
  lbl_news: "Bài viết",
  lbl_role: "Quyền",
  lbl_user_request: "Quản lý yêu cầu người dùng",
  lbl_login: "Đăng nhập",
  lbl_welcome: "Chào mừng đến mới trang quản trị {value}",
  lbl_user_edit_info: "Chỉnh sửa thông tin người dùng",
  lbl_user_add_info: "Thêm người dùng",
  lbl_user_list: "Danh sách người dùng",
  lbl_manage_user: "Quản lý người dùng",
  ROLE_ADMIN: "Admin",
  ROLE_MANAGER: "Quản lý",
  ROLE_DRIVER: "Tài xế",
  ROLE_USER: "Người dùng",
  lbl_user_count: "{value} người dùng",
  lbl_change_language: "Ngôn ngữ",
  lbl_language_en: "English",
  lbl_language_vi: "Tiếng việt",
  lbl_account: "Tài khoản",
  lbl_logout: "Đăng xuất",
  lbl_manage_restaurant: "Quản lý nhà hàng",
  lbl_total_count: "Tổng cộng {value}",
  RESTAURANT_name: "Tên nhà hàng",
  RESTAURANT_address: "Địa chỉ",
  RESTAURANT_open_time: "Giờ mở cửa",
  RESTAURANT_stop_time: "Giờ đóng cửa",
  RESTAURANT_managers: "Ban quản lý",
  RESTAURANT_status: "Trạng thái",
  lbl_action: "Hành động",
  RESTAURANT_status_active: "Đang hoạt động",
  RESTAURANT_status_inactive: "Không hoạt động",
  RESTAURANT_phone: "Số điện thoại",
  RESTAURANT_opentime: {
    day: "Ngày mở cửa",
    time: "Giở mở cửa",
  },
  RESTAURANT_location: {
    lat: "Vĩ độ",
    long: "Kinh độ",
  },
  lbl_manage_restaurant_catalog: "Danh mục nhà hàng",
  lbl_notification: "Thông báo",
  lbl_customer_catalog: "Danh mục khách hàng",
  lbl_support: "Hỗ trợ",
  lbl_promotion: "Khuyễn mãi",
  lbl_add_info: "Thêm mới",
  lbl_edit_info: "Chỉnh sửa",
  lbl_filter: "Lọc",
  lbl_type: "Loại",
  lbl_count: "Số lượng",
  lbl_queue_number: "Số thứ tự",
  lbl_icon: "Icon",

  btn_edit: "Chỉnh sửa",
  btn_add: "Thêm",
  btn_save: "Lưu",
  btn_cancel: "Hủy",
  btn_delete: "Xóa",
  btn_deposit: "Nạp tiền",
  btn_detail: "Chi tiết",
  btn_confirm: "Xác nhận",
  btn_add_filter: "Thêm bộ lọc",
  btn_reset: "Xóa lọc",
  btn_previous: "Trước",
  btn_next: "Sau",
  btn_view: "Xem chi tiết",
  advanced_setting: "Cài đặt nâng cao",
  country: "Quốc gia",
  region: "Khu vực",
  driver: "Tài xế",
  field_group: "Nhóm lĩnh vực",
  product: "Sản phẩm",
  fee_manage: "Quản lí phí",
  order: "Đơn hàng",
  partner: "Đối tác",
  staff: "Nhân viên",
  revenue: "Doanh thu",

  "Trang chủ": "Trang chủ",
  "Cài đặt nâng cao": "Cài đặt nâng cao",

  "Quốc gia": "Quốc gia",
  "Tạo quốc gia": "Tạo quốc gia",
  "Chi tiết quốc gia": "Chi tiết quốc gia",

  "Khu vực": "Khu vực",
  "Tạo khu vực": "Tạo khu vực",
  "Chi tiết khu vực": "Chi tiết khu vực",

  "Tài xế": "Tài xế",
  "Tạo tài xế": "Tạo tài xế",
  "Chi tiết tài xế": "Chi tiết tài xế",

  "Nhóm lĩnh vực": "Nhóm lĩnh vực",
  "Tạo nhóm lĩnh vực": "Tạo nhóm lĩnh vực",
  "Chi tiết nhóm lĩnh vực": "Chi tiết nhóm lĩnh vực",

  "Sản phẩm": "Sản phẩm",
  "Tạo sản phẩm": "Tạo sản phẩm",
  "Chi tiết sản phẩm": "Chi tiết sản phẩm",

  "Quản lí phí": "Quản lí phí",
  "Tạo phí": "Tạo phí",
  "Chi tiết phí": "Tạo phí",

  "Đơn hàng": "Đơn hàng",
  "Tạo đơn hàng": "Tạo đơn hàng",
  "Chi tiết đơn hàng": "Chi tiết đơn hàng",

  "Đối tác": "Đối tác",
  "Tạo đối tác": "Tạo đối tác",
  "Chi tiết đối tác": "Chi tiết đối tác",

  "Nhân viên": "Nhân viên",
  "Tạo nhân viên": "Tạo nhân viên",
  "Chi tiết nhân viên": "Chi tiết nhân viên",

  "Doanh thu": "Doanh thu",
  "Tạo doanh thu": "Tạo doanh thu",
  "Chi tiết doanh thu": "Chi tiết doanh thu",

  // Columns
  "ID.": "ID.",
  Tên: "Tên",
  "Mã quốc gia": "Mã quốc gia",
  "Trạng thái": "Trạng thái",
  "Số điện thoại": "Số điện thoại",
  "Địa chỉ": "Địa chỉ",
  "Nhóm tài xế": "Nhóm tài xế",
  "Hoa hồng": "Hoa hồng",
  "Tiền lương": "Tiền lương",
  "Đơn vị": "Đơn vị",
  "Loại sản phẩm": "Loại sản phẩm",
  "Tổng tiền": "Tổng tiền",
  "Phí ship": "Phí ship",
  "Hình ảnh": "Hình ảnh",
  "Mức phí": "Mức phí",
  "Ưu tiên": "Ưu tiên",

  // Buttons
  Lọc: "Lọc",
  "Đặt lại": "Đặt lại",
};
