/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSectorDto } from '../models/CreateSectorDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateSectorDto } from '../models/UpdateSectorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminSectorService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create sector
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public sectorAdminControllerCreate(
        requestBody: CreateSectorDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/sector',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all sector
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param countryId
     * @param regionId
     * @param unit
     * @returns any
     * @throws ApiError
     */
    public sectorAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        countryId?: string,
        regionId?: string,
        unit?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/sector',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'country_id': countryId,
                'region_id': regionId,
                'unit': unit,
            },
        });
    }

    /**
     * Get detail sector
     * @param id
     * @returns any
     * @throws ApiError
     */
    public sectorAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/sector/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update sector
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public sectorAdminControllerUpdate(
        id: number,
        requestBody: UpdateSectorDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/sector/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete sector
     * @param id
     * @returns any
     * @throws ApiError
     */
    public sectorAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/sector/{id}',
            path: {
                'id': id,
            },
        });
    }

}
