import { Select as SelectAntd, SelectProps } from "antd";
import { integrateInputClassname } from "components/input";
import React from "react";

export const SelectTag: React.FC<SelectProps> = ({ ...props }) => {
  return (
    <SelectAntd
      {...props}
      className={integrateInputClassname(props.className)}
    />
  );
};
