import { Button } from "antd";
import { ReactNode } from "react";

type ImExPortButtonType = {
  children: ReactNode;
  className?: string;
};

export function StatusButton(props: ImExPortButtonType) {
  const { children, className } = props;
  return (
    <Button {...props} className={`rounded-[16px] p-0 px-3 ${className}`}>
      {children}
    </Button>
  );
}
