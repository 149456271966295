import { Layout as LayoutAnt } from "antd";
import React, { useState } from "react";

import ActivityIndicator from "components/activity-indicator";
import Breadcrumb from "components/breadcrumbs";
import { PopupNotify } from "components/popupNotify";
import { Outlet } from "react-router-dom";
import Header from "./header";
import "./index.css";
import SidebarComponent from "./sidebar/sidebar.component";
const { Content } = LayoutAnt;

export function Layout() {
  const [openNotify, setOpenNotify] = useState(false);

  return (
    <LayoutAnt className="app-container">
      <Header />
      <PopupNotify
        open={openNotify}
        handleCancel={() => setOpenNotify(false)}
      />
      <LayoutAnt>
        <SidebarComponent />
        <Content style={{ padding: 16 }}>
          <Breadcrumb />
          <React.Suspense fallback={<ActivityIndicator />}>
            <Outlet />
          </React.Suspense>
        </Content>
      </LayoutAnt>
    </LayoutAnt>
  );
};
