import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

export const LinkWithSearch: React.FC<LinkProps> = ({ to, ...props }) => {
  const { search } = useLocation();
  const { className } = props;
  return (
    <Link {...props} to={to + search} className={`text-red-500 ${className}`} />
  );
};
