import { Checkbox, Col, Form, Modal, Row, Select, Typography } from "antd";
import { CKEditor, Input, TextArea } from "components";
import { useAsync } from "hooks/useAsync";
import useCheckPermission from "hooks/useCheckPermission";
import useDebounce from "hooks/useDebounce";
// import { GROUP_CUSTOMER } from "pages/user/constants";
import { useNotify } from "providers/notify";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { apiAdminUserGetList } from "services/adminUser";
import { apiNotificationCreate } from "services/notification";
import "./index.css";

interface PopupNotifyProps {
  open?: boolean;
  handleCancel?: () => void;
}

type FormType = {
  is_all?: boolean;
  account_type?: Array<string>;
  user_id?: Array<number>;
  title: string;
  body: string;
  content: string;
  type: string; // trick
};

const OPTIONS_SELECT = [
  {
    value: "is_all",
    label: "Chọn tất cả",
  },
  {
    value: "account_type",
    label: "Nhóm khách hàng",
  },
  {
    value: "user_id",
    label: "Khách hàng",
  },
];

export const PopupNotify: React.FC<PopupNotifyProps> = ({
  open,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const { fnCheck } = useCheckPermission();
  const acceptCallGetListUser = useMemo(
    () => fnCheck(["notification.create", "user.get_list"]),
    [fnCheck]
  );

  const { api } = useNotify();

  const [execute, state] = useAsync(apiNotificationCreate, {
    onSuccess: () => {
      api!.success({
        message: `Thông báo`,
        description: `Gửi thông báo thành công!`,
      });
      if (handleCancel) handleCancel();
    },
    onFailed: () => {
      api!.error({
        message: `Thông báo`,
        description: `Gửi thông báo lỗi!`,
      });
    },
  });

  const onFinish = (e: FormType) => {
    let body = {
      title: e.title,
      body: e.body,
      content: e.content,
    } as any;
    const type = e.type;
    body[type] = (e as any)[type];
    execute(body);
  };

  const [optionsUser, setOptionsUser] = useState<
    Array<{ value: number; label: string }>
  >([]);

  const [valPhone, setValPhone] = useState("");
  const [page, setPage] = useState(1);
  const phone_number = useDebounce(valPhone);
  const refSelect = useRef<string>("");

  const [, stateList] = useAsync(apiAdminUserGetList, {
    callOnFirst: acceptCallGetListUser && open,
    callOnFirstArgs: [{ page, limit: 20, phone_number }],
    deps: [page, phone_number, open],
    onSuccess: (res) => {
      const newData = res.data.data.map((x) => ({
        value: x.id,
        label: x.phone_number,
      }));
      if (res.data.metadata?.page === 1) {
        setOptionsUser(newData);
      } else {
        setOptionsUser((prev) => [...prev, ...newData]);
      }
    },
  });

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      className="modal-notify"
      title={
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Gửi thông báo
        </Typography.Title>
      }
      open={open}
      onCancel={handleCancel}
      okText="Gửi"
      okButtonProps={{ loading: state.loading }}
      onOk={() => {
        form.submit();
      }}
    >
      <div className="popup-notify">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              <Form.Item
                label="Loại"
                name="type"
                rules={[{ required: true, message: "Vui lòng nhập!" }]}
                style={{ marginBottom: 0 }}
              >
                <Select options={OPTIONS_SELECT} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {({ getFieldValue, setFieldValue }) => {
                  const type = getFieldValue("type");
                  if (type === "is_all") {
                    return (
                      <Form.Item
                        initialValue={true}
                        valuePropName="checked"
                        name="is_all"
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          onChange={() => setFieldValue("is_all", true)}
                        >
                          Gửi tất cả
                        </Checkbox>
                      </Form.Item>
                    );
                  }
                  // if (type === "account_type") {
                  //   return (
                  //     <Form.Item
                  //       label="Nhóm khách hàng"
                  //       name="account_type"
                  //       rules={[{ required: true, message: "Vui lòng nhập!" }]}
                  //       style={{ marginBottom: 0 }}
                  //     >
                  //       <Select mode="multiple" options={GROUP_CUSTOMER} />
                  //     </Form.Item>
                  //   );
                  // }
                  if (type === "user_id") {
                    return (
                      <Form.Item
                        label="Khách hàng"
                        name="user_id"
                        rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        style={{ marginBottom: 0 }}
                      >
                        <Select
                          mode="multiple"
                          optionFilterProp="label"
                          options={optionsUser}
                          loading={stateList.loading}
                          onDropdownVisibleChange={(openSelect) => {
                            if (openSelect) {
                              setPage(1);
                              setValPhone(refSelect.current || "");
                            }
                          }}
                          onSelect={(_, val) => {
                            refSelect.current = _.label;
                          }}
                          onSearch={(val) => {
                            setPage(1);
                            setValPhone(val);
                          }}
                          onPopupScroll={(event) => {
                            const target = event.target as any;
                            if (
                              acceptCallGetListUser &&
                              stateList.data?.data.metadata &&
                              stateList.data?.data.metadata.page <
                                stateList.data?.data.metadata.total_page &&
                              !stateList.loading &&
                              target?.scrollTop + 50 >=
                                target?.scrollHeight - target?.offsetHeight
                            ) {
                              setPage(stateList.data?.data.metadata?.page + 1);
                            }
                          }}
                        />
                      </Form.Item>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tiêu đề"
                name="title"
                rules={[{ required: true, message: "Vui lòng nhập!" }]}
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tin nhắn"
                name="body"
                rules={[{ required: true, message: "Vui lòng nhập!" }]}
                style={{ marginBottom: 0 }}
              >
                <TextArea />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Nội dung"
                name="content"
                rules={[{ required: true, message: "Vui lòng nhập!" }]}
                style={{ marginBottom: 0 }}
              >
                <CKEditor />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
