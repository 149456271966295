/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Upload a single file
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public utilsControllerUploadSingle(
        formData: {
            file?: Blob;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/utils/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Upload multiple files
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public utilsControllerUploadMultiple(
        formData: {
            files?: Blob;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/utils/upload_multiple',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get cadastral Viet Nam
     * @param cityId
     * @param districtId
     * @returns any
     * @throws ApiError
     */
    public utilsControllerGetCadastral(
        cityId?: string,
        districtId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/utils/cadastral_vn',
            query: {
                'city_id': cityId,
                'district_id': districtId,
            },
        });
    }

}
