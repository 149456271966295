import React from "react";

interface FlexProps extends React.CSSProperties {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Flex: React.FC<FlexProps> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        ...props,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
