/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateStaffDto = {
    name?: string;
    password?: string;
    hometown?: string;
    birthday?: string;
    gender?: UpdateStaffDto.gender;
    address?: string;
    images?: Array<string>;
    region_id?: number;
    salary?: number;
    commission?: number;
    phone_number?: string;
    bank_name?: string;
    bank_number?: string;
};

export namespace UpdateStaffDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }


}

