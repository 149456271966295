/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFeeDto } from '../models/CreateFeeDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateFeeDto } from '../models/UpdateFeeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminFeeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create fee
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerCreate(
        requestBody: CreateFeeDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/fee',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all fee
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param countryId
     * @param regionId
     * @param sectorId
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        countryId?: string,
        regionId?: string,
        sectorId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/fee',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'country_id': countryId,
                'region_id': regionId,
                'sector_id': sectorId,
            },
        });
    }

    /**
     * Get detail fee
     * @param id
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/fee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update fee
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerUpdate(
        id: number,
        requestBody: UpdateFeeDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/fee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete fee
     * @param id
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/fee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get detail fee form
     * @param id
     * @returns any
     * @throws ApiError
     */
    public feeAdminControllerGetFeeFormDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/fee/{id}/form',
            path: {
                'id': id,
            },
        });
    }

}
