import qs from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const LIMIT = 10;
export const RANGE_TIME_SEARCH = "range_time";

interface IParams {
  page: number;
  limit: number;
  search_type?: string;
  key_words?: string;
  order_by?: string;
  sort?: string;
  [key: string]: any;
}

const useSearch = () => {
  const [limit, setLimit] = useState(LIMIT);

  const [searchParams, setSearchParams] = useSearchParams();

  const setPage = useCallback(
    (_page: number) => {
      searchParams.set("page", _page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const search_type = useMemo(
    () => searchParams.get("search_type"),
    [searchParams]
  );

  const key_words = useMemo(
    () => searchParams.get("key_words"),
    [searchParams]
  );

  const setFilter = useCallback(
    (object: { [key: string]: unknown }) => {
      const currentParams = Object.fromEntries([...searchParams]);

      const newParams = {
        ...currentParams,
        ...object,
      };

      setSearchParams(new URLSearchParams(qs.stringify(newParams)));
    },
    [searchParams, setSearchParams]
  );

  const resetFilter = useCallback(() => {
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    searchParams.delete("search_type");
    searchParams.delete("key_words");
    searchParams.delete("order_by");
    searchParams.delete("sort");
    searchParams.set("page", "1");
    setLimit(LIMIT);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const params: IParams = useMemo(() => {
    const __params: any = Object.fromEntries(searchParams);
    const _params: IParams = {
      ...__params,
      page: __params.page || 1,
      limit: __params.limit || LIMIT,
    };
    if (search_type && key_words) {
      _params[search_type] = key_words;
    }
    return _params;
  }, [key_words, searchParams, search_type]);

  return useMemo(
    () => ({
      setPage,
      setLimit,
      setFilter,
      resetFilter,
      params,
      search_type,
      key_words,
    }),
    [setPage, setFilter, resetFilter, params, search_type, key_words]
  );
};

export default useSearch;
