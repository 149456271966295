import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

type ImExPortButtonType = {
  isImport?: boolean;
  text?: string;
};

export function ImExPortButton(props: ImExPortButtonType) {
  const { t } = useTranslation();
  const { isImport = false, text = "" } = props;

  return (
    <Button
      className="flex justify-center items-center"
      icon={ isImport? <ArrowDownOutlined />: <ArrowUpOutlined/>}
      {...props}
    >
      {t(text)}
    </Button>
  );
}
