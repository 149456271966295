/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderBy } from '../models/OrderBy';
import type { UpdateRoleDto } from '../models/UpdateRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminRoleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list role
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param type
     * @returns any
     * @throws ApiError
     */
    public roleAdminControllerFindAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        type?: 'admin' | 'staff' | 'partner',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/role',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'type': type,
            },
        });
    }

    /**
     * Get list permission
     * @returns any
     * @throws ApiError
     */
    public roleAdminControllerGetAllPermission(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/role/permission',
        });
    }

    /**
     * Get detail role
     * @param id
     * @returns any
     * @throws ApiError
     */
    public roleAdminControllerFindOne(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a role
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public roleAdminControllerUpdate(
        id: number,
        requestBody: UpdateRoleDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/role/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
