/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateAdminDto = {
    name?: string;
    password?: string;
    hometown?: string;
    birthday?: string;
    gender?: UpdateAdminDto.gender;
    address?: string;
    images?: Array<string>;
};

export namespace UpdateAdminDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
    }


}

