import { SVGProps } from "react";

export function PlusIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  fill = "none",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 2.66663C8.28273 2.66663 8.55405 2.77901 8.75408 2.97905C8.95412 3.17908 9.0665 3.45039 9.0665 3.73329V6.93329H12.2665C12.5494 6.93329 12.8207 7.04567 13.0208 7.24571C13.2208 7.44575 13.3332 7.71706 13.3332 7.99996C13.3332 8.28286 13.2208 8.55417 13.0208 8.75421C12.8207 8.95424 12.5494 9.06663 12.2665 9.06663H9.0665V12.2666C9.0665 12.5495 8.95412 12.8208 8.75408 13.0209C8.55405 13.2209 8.28273 13.3333 7.99984 13.3333C7.71694 13.3333 7.44563 13.2209 7.24559 13.0209C7.04555 12.8208 6.93317 12.5495 6.93317 12.2666V9.06663H3.73317C3.45027 9.06663 3.17896 8.95424 2.97892 8.75421C2.77888 8.55417 2.6665 8.28286 2.6665 7.99996C2.6665 7.71706 2.77888 7.44575 2.97892 7.24571C3.17896 7.04567 3.45027 6.93329 3.73317 6.93329H6.93317V3.73329C6.93317 3.45039 7.04555 3.17908 7.24559 2.97905C7.44563 2.77901 7.71694 2.66663 7.99984 2.66663Z"
        fill="currentColor"
      />
    </svg>
  );
}
