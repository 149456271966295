/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateOrderDto = {
    status?: UpdateOrderDto.status;
    customer_name?: string;
    customer_phone?: string;
    address?: string;
    product_id?: number;
    fee_form_id?: number;
    quantity?: number;
    type_fee_payer?: UpdateOrderDto.type_fee_payer;
    cash_on_delivery?: number;
    has_check?: boolean;
    note?: string;
};

export namespace UpdateOrderDto {

    export enum status {
        PENDING = 'pending',
        TRANSPORTING = 'transporting',
        SHIPPED = 'shipped',
    }

    export enum type_fee_payer {
        SHOP = 'shop',
        CUSTOMER = 'customer',
    }


}

