/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendOtpDto } from '../models/SendOtpDto';
import type { VerifyOtpDto } from '../models/VerifyOtpDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OtpService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send OTP
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public otpControllerSendOtp(
        requestBody: SendOtpDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/otp/send',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Verify an OTP
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public otpControllerVerifyOtp(
        requestBody: VerifyOtpDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/otp/verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
