import { SVGProps } from "react";

export function MenuIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_2448_13093)">
        <path
          d="M2.5 4.00488C2.5 3.17646 3.17157 2.50488 4 2.50488H9C9.82843 2.50488 10.5 3.17646 10.5 4.00488V9.00488C10.5 9.83331 9.82843 10.5049 9 10.5049H4C3.17157 10.5049 2.5 9.83331 2.5 9.00488V4.00488ZM2.5 15.0049C2.5 14.1765 3.17157 13.5049 4 13.5049H9C9.82843 13.5049 10.5 14.1765 10.5 15.0049V20.0049C10.5 20.8333 9.82843 21.5049 9 21.5049H4C3.17157 21.5049 2.5 20.8333 2.5 20.0049V15.0049ZM13.5 4.00488C13.5 3.17646 14.1716 2.50488 15 2.50488H20C20.8284 2.50488 21.5 3.17646 21.5 4.00488V9.00488C21.5 9.83331 20.8284 10.5049 20 10.5049H15C14.1716 10.5049 13.5 9.83331 13.5 9.00488V4.00488ZM13.5 17.5049C13.5 15.2957 15.2909 13.5049 17.5 13.5049C19.7091 13.5049 21.5 15.2957 21.5 17.5049C21.5 19.714 19.7091 21.5049 17.5 21.5049C15.2909 21.5049 13.5 19.714 13.5 17.5049Z"
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_2448_13093">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.00292969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
