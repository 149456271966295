import { Error, Layout } from "components";
import ActivityIndicator from "components/activity-indicator/activity-indicator.component";
import { PATHS, ROUTES } from "constants/routes";
import { withPrivate } from "hocs/withPrivate";
import React, { Suspense } from "react";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

const Login = React.lazy(() => import("pages/login/detail"));

const PAGE_ROUTES = ROUTES.map((x: any) => ({
  ...x,
  element: React.lazy(() => import(`pages/${x.page}`)),
}));

const OutletHoc = withPrivate(Outlet);

function App() {
  return (
    <Router>
      <Suspense fallback={null}>
        <Routes>
          <Route path={PATHS.login} element={<Login />} />
          <Route path="/" element={<OutletHoc />}>
            <Route path="/" element={<Layout />}>
              {/* Default path */}
              <Route path="/" element={<Navigate to={PATHS.home} />} />

              {/* Import paths */}
              {PAGE_ROUTES.map((item, index) => {
                const Page = (item as any).element || null;
                return (
                  <Route
                    key={index}
                    path={item.href}
                    element={
                      <Suspense fallback={<ActivityIndicator />}>
                        <Page />
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>

            {/* Not found fallback page */}
            <Route path={"*"} element={<Error error="404" />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
