/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDriverGroupDto } from '../models/CreateDriverGroupDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateDriverGroupDto } from '../models/UpdateDriverGroupDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminDriverGroupService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create driver group
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public driverGroupAdminControllerCreate(
        requestBody: CreateDriverGroupDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/driver-group',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all driver group
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param countryId
     * @param regionId
     * @returns any
     * @throws ApiError
     */
    public driverGroupAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        countryId?: string,
        regionId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/driver-group',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'country_id': countryId,
                'region_id': regionId,
            },
        });
    }

    /**
     * Get detail driver group
     * @param id
     * @returns any
     * @throws ApiError
     */
    public driverGroupAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/driver-group/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update driver group
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public driverGroupAdminControllerUpdate(
        id: number,
        requestBody: UpdateDriverGroupDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/driver-group/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete driver group
     * @param id
     * @returns any
     * @throws ApiError
     */
    public driverGroupAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/driver-group/{id}',
            path: {
                'id': id,
            },
        });
    }

}
