import { PlusOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";

export function AddNewButton(props: ButtonProps) {
  return (
    <Button
      className="flex justify-center items-center bg-[var(--app-bg-color)] text-white font-bold"
      icon={<PlusOutlined />}
      {...props}
    />
  );
}
