import React from "react";

import { PATHS } from "constants/routes";
import { DOMAIN } from "constants/schema";
import { authStorage } from "helpers/localStorage";
import { AuthAction, useAuth } from "providers/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { MainServices } from "services/ServicesConfig";
import { MainServer } from "services/main";
import { useNotify } from "../providers/notify";
import { useAsync } from "../hooks/useAsync";

export const withPrivate =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const navigate = useNavigate();
    const token = authStorage.get("token");
    const { dispatch, state } = useAuth();

    if (token) {
      MainServices.services = new MainServer({
        BASE: DOMAIN,
        TOKEN: token,
      });
    }

    const { api } = useNotify();

    useAsync(
      MainServices.services.admin.adminControllerGetMe.bind(
        MainServices.services.admin
      ),
      {
        onSuccess: (res) => {
          dispatch({
            type: AuthAction.setAuth,
            payload: res?.data,
          });
        },
        onFailed: () => {
          navigate(PATHS.login, { replace: true });
        },
        callOnFirst: token,
      }
    );

    // useAsync(apiGetMasterData, {
    //   onSuccess: (res) => {
    //     dispatchMasterData({
    //       type: MasterDataAction.setMasterData,
    //       payload: res.data.data.result[0],
    //     });
    //   },
    //   onFailed: () => {
    //     api!.error({
    //       message: `Thông báo`,
    //       description: `Request master data error`,
    //     });
    //   },
    //   callOnFirst: true,
    // });

    // useAsync(apiGetDistrict, {
    //   onSuccess: (res) => {
    //     dispatchMasterData({
    //       type: MasterDataAction.setDistrict,
    //       payload: { districts: res.data.data.result},
    //     });
    //   },
    //   onFailed: () => {
    //     api!.error({
    //       message: `Thông báo`,
    //       description: `Request master data error`,
    //     });
    //   },
    //   callOnFirst: true,
    // });

    if (!token) {
      return <Navigate to={PATHS.login} replace />;
    }

    if (state) {
      return <WrappedComponent {...props} />;
    }

    return <div />;
  };
