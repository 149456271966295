/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminService } from './services/AdminService';
import { AdminChatService } from './services/AdminChatService';
import { AdminCountryService } from './services/AdminCountryService';
import { AdminDriverGroupService } from './services/AdminDriverGroupService';
import { AdminFeeService } from './services/AdminFeeService';
import { AdminOrderService } from './services/AdminOrderService';
import { AdminPartnerGroupService } from './services/AdminPartnerGroupService';
import { AdminProductService } from './services/AdminProductService';
import { AdminRegionService } from './services/AdminRegionService';
import { AdminRoleService } from './services/AdminRoleService';
import { AdminSectorService } from './services/AdminSectorService';
import { AdminUserService } from './services/AdminUserService';
import { OtpService } from './services/OtpService';
import { PublicService } from './services/PublicService';
import { UserChatService } from './services/UserChatService';
import { UtilsService } from './services/UtilsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class MainServer {

    public readonly admin: AdminService;
    public readonly adminChat: AdminChatService;
    public readonly adminCountry: AdminCountryService;
    public readonly adminDriverGroup: AdminDriverGroupService;
    public readonly adminFee: AdminFeeService;
    public readonly adminOrder: AdminOrderService;
    public readonly adminPartnerGroup: AdminPartnerGroupService;
    public readonly adminProduct: AdminProductService;
    public readonly adminRegion: AdminRegionService;
    public readonly adminRole: AdminRoleService;
    public readonly adminSector: AdminSectorService;
    public readonly adminUser: AdminUserService;
    public readonly otp: OtpService;
    public readonly public: PublicService;
    public readonly userChat: UserChatService;
    public readonly utils: UtilsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminService(this.request);
        this.adminChat = new AdminChatService(this.request);
        this.adminCountry = new AdminCountryService(this.request);
        this.adminDriverGroup = new AdminDriverGroupService(this.request);
        this.adminFee = new AdminFeeService(this.request);
        this.adminOrder = new AdminOrderService(this.request);
        this.adminPartnerGroup = new AdminPartnerGroupService(this.request);
        this.adminProduct = new AdminProductService(this.request);
        this.adminRegion = new AdminRegionService(this.request);
        this.adminRole = new AdminRoleService(this.request);
        this.adminSector = new AdminSectorService(this.request);
        this.adminUser = new AdminUserService(this.request);
        this.otp = new OtpService(this.request);
        this.public = new PublicService(this.request);
        this.userChat = new UserChatService(this.request);
        this.utils = new UtilsService(this.request);
    }
}

