/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateOrderDto = {
    customer_name: string;
    customer_phone: string;
    address: string;
    product_id: number;
    fee_form_id: number;
    quantity: number;
    type_fee_payer: CreateOrderDto.type_fee_payer;
    cash_on_delivery?: number;
    has_check?: boolean;
    note?: string;
};

export namespace CreateOrderDto {

    export enum type_fee_payer {
        SHOP = 'shop',
        CUSTOMER = 'customer',
    }


}

