import { SVGProps } from "react";

export function FeeIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_3035_25420)">
        <path
          d="M17.3989 10.0326C17.3989 14.1336 14.1003 17.3877 9.98437 17.3728C5.9279 17.3728 2.65896 14.0445 2.68868 9.95827C2.70354 5.88695 6.0022 2.618 10.0735 2.63286C14.1448 2.64772 17.4138 5.94638 17.3989 10.0326ZM16.8046 10.0326C16.8194 6.31786 13.8031 3.25693 10.0884 3.24208C6.34395 3.22722 3.28303 6.25842 3.26817 9.98799C3.25331 13.673 6.28452 16.719 9.93979 16.7636C13.6248 16.8231 16.8046 13.8364 16.8046 10.0326Z"
          fill={stroke}
        />
        <path
          d="M18.647 9.03671C18.543 8.54637 18.4836 8.08574 18.335 7.63998C17.5921 5.18827 16.0616 3.39035 13.7585 2.24622C13.6247 2.18678 13.4762 2.12735 13.387 2.03819C13.3127 1.9639 13.2681 1.80045 13.3127 1.7113C13.3573 1.62215 13.5059 1.54785 13.6099 1.54785C13.7288 1.54785 13.8476 1.62215 13.9665 1.66672C16.76 3.06345 18.4836 5.27742 19.1225 8.33834C19.1671 8.54636 19.2117 8.75439 19.2562 9.00699C19.39 8.94755 19.494 8.8584 19.6129 8.8584C19.7169 8.84354 19.91 8.87326 19.9397 8.94755C19.9843 9.05157 19.9843 9.25959 19.91 9.33388C19.6574 9.55677 19.3603 9.74993 19.0779 9.9431C19.0185 9.98767 18.8996 9.97281 18.8253 9.92824C18.6025 9.83908 18.3944 9.74993 18.2013 9.6162C18.0973 9.54191 17.9932 9.39332 18.0081 9.27445C18.023 9.08128 18.1864 9.02185 18.3647 9.05157C18.439 9.03671 18.4984 9.03671 18.647 9.03671Z"
          fill={stroke}
        />
        <path
          d="M10.4897 19.3044C10.5343 19.4381 10.6086 19.557 10.6086 19.661C10.6086 19.765 10.564 19.9285 10.4749 19.973C10.3857 20.0176 10.1925 20.0028 10.1331 19.9433C9.92509 19.6759 9.73193 19.3787 9.58334 19.0666C9.53876 18.9478 9.62791 18.7546 9.68735 18.6209C9.76164 18.4574 9.8508 18.294 9.96967 18.1603C10.044 18.0711 10.2074 17.9819 10.2817 18.0117C10.3857 18.0562 10.46 18.2048 10.5046 18.3237C10.5343 18.398 10.4897 18.4872 10.4749 18.606C11.1584 18.6506 11.7973 18.502 12.4214 18.3237C14.6651 17.6551 16.359 16.3029 17.5477 14.297C17.6071 14.1929 17.6665 14.0741 17.7408 13.9701C17.8449 13.8066 17.9934 13.7472 18.1569 13.8363C18.3352 13.9255 18.3203 14.1038 18.246 14.2672C18.2015 14.3713 18.1569 14.4604 18.0975 14.5496C16.4333 17.343 13.9964 18.9181 10.7572 19.245C10.6977 19.245 10.6532 19.2598 10.5937 19.2598C10.5789 19.2598 10.564 19.2598 10.4897 19.3044Z"
          fill={stroke}
        />
        <path
          d="M9.61312 0.760487C9.52397 0.46331 9.28623 0.195851 9.64284 0.0324038C9.94002 -0.101326 10.0292 0.21071 10.1629 0.389016C10.1778 0.403875 10.1778 0.418734 10.1926 0.433593C10.6384 1.1171 10.6235 1.19139 10.1778 1.83032C10.0737 1.97891 9.96974 2.09778 9.77657 2.03835C9.53883 1.96405 9.55369 1.78575 9.59827 1.59258C9.61313 1.54801 9.59827 1.48857 9.61312 1.35484C9.03363 1.45885 8.48385 1.50343 7.94893 1.65202C5.57152 2.27609 3.75874 3.65796 2.52546 5.78278C2.46602 5.88679 2.42145 6.03538 2.33229 6.10967C2.22828 6.18396 2.07969 6.27312 1.97568 6.2434C1.79737 6.19882 1.73794 6.03538 1.82709 5.85707C1.87167 5.75306 1.91625 5.6639 1.97568 5.55989C3.55072 2.82587 5.89841 1.23597 9.04849 0.84964C9.2268 0.834781 9.4051 0.790205 9.61312 0.760487Z"
          fill={stroke}
        />
        <path
          d="M0.712385 9.88346C0.474644 9.91318 0.192326 10.1509 0.0585964 9.79431C-0.0454155 9.48227 0.251761 9.42284 0.444926 9.28911C0.459785 9.27425 0.474644 9.27425 0.489503 9.25939C1.20273 8.8582 1.26216 8.87306 1.87137 9.3634C2.01996 9.48227 2.13883 9.63086 2.03482 9.82403C1.94567 10.0023 1.78222 10.0023 1.61877 9.92804C1.5742 9.91318 1.51476 9.91318 1.42561 9.91318C1.39589 10.6561 1.4999 11.3693 1.67821 12.0677C2.22799 14.252 3.44641 15.9756 5.31863 17.2386C5.45235 17.3277 5.6158 17.402 5.70496 17.5209C5.76439 17.6101 5.76439 17.8032 5.71981 17.9072C5.6901 17.9667 5.48207 18.0112 5.39292 17.9815C5.21461 17.9072 5.03631 17.8032 4.87286 17.6844C2.36172 15.9013 1.00956 13.4942 0.816397 10.4481C0.801538 10.2847 0.78668 10.1212 0.771821 9.95776C0.771821 9.9429 0.756962 9.92804 0.712385 9.88346Z"
          fill={stroke}
        />
        <path
          d="M10.0586 15.352C7.08682 15.3668 4.69455 12.9746 4.67969 10.0177C4.67969 7.04589 7.0571 4.68333 10.0289 4.65361C12.7926 4.6239 15.3929 6.85272 15.4078 9.98794C15.4226 12.9597 13.0304 15.352 10.0586 15.352ZM10.2963 7.49165C10.2815 7.38764 10.2815 7.26877 10.2369 7.17962C10.2072 7.10532 10.118 7.01617 10.0586 7.01617C9.99915 7.01617 9.91 7.10532 9.88028 7.16476C9.83571 7.26877 9.82085 7.4025 9.79113 7.52137C9.70198 7.55109 9.62768 7.56595 9.55339 7.58081C8.79559 7.74425 8.3944 8.14544 8.33496 8.76951C8.26067 9.4976 8.55784 9.94336 9.3305 10.2405C9.49395 10.3 9.6574 10.3743 9.79113 10.4337C9.79113 10.8052 9.79113 11.1321 9.79113 11.5035C9.56825 11.4441 9.38994 11.3995 9.22649 11.355C9.10762 11.3252 8.98875 11.2807 8.85502 11.2807C8.61728 11.2658 8.42412 11.3698 8.34982 11.6076C8.27553 11.8453 8.36468 12.0385 8.58756 12.1573C8.79559 12.2613 9.01847 12.3356 9.24135 12.4099C9.41966 12.4694 9.62768 12.4991 9.70198 12.5139C9.85056 12.7368 9.92486 12.9894 10.0289 13.0043C10.2963 13.034 10.2072 12.7665 10.2666 12.6031C10.2815 12.5734 10.2963 12.5437 10.3409 12.4842C10.4895 12.4396 10.6678 12.3951 10.8312 12.3208C11.4256 12.083 11.7525 11.6521 11.7674 10.9983C11.7822 10.3743 11.5148 10.0177 10.8461 9.7502C10.6529 9.6759 10.4598 9.58675 10.2518 9.51245C10.2666 9.18556 10.1775 8.87352 10.326 8.54663C10.5192 8.59121 10.6975 8.65064 10.861 8.68036C11.173 8.7398 11.4405 8.60607 11.5148 8.35347C11.589 8.11572 11.4702 7.89284 11.173 7.75911C10.9204 7.64024 10.6381 7.59566 10.2963 7.49165Z"
          fill={stroke}
        />
        <path
          d="M10.2959 10.627C10.7714 10.9241 10.7714 11.147 10.2959 11.4145C10.2959 11.1619 10.2959 10.9241 10.2959 10.627Z"
          fill={stroke}
        />
        <path
          d="M9.82104 8.62077C9.82104 8.82879 9.82104 9.03682 9.82104 9.28942C9.55358 9.27456 9.41985 9.14083 9.43471 8.88823C9.44957 8.6802 9.55358 8.56133 9.82104 8.62077Z"
          fill={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_3035_25420">
          <rect
            width="19.9406"
            height="20"
            fill="white"
            transform="translate(0.0292969 0.00292969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
