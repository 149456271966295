import React, { createContext, useContext, useMemo, useReducer } from "react";
import { IAdmin } from "services/admin/types";

export type TypeRental = "DAY" | "MONTH" | "FOREVER";
export type HouseType = "APARTMENT" | "HOMESTAY" | "VILLA";
export type VehicleType =
  | "MOTOR_BIKE"
  | "CAR_4"
  | "CAR_7"
  | "CAR_16"
  | "SUPER_CAR";
export type Gender = "MALE" | "FEMALE" | "OTHER";
export type OtpType = "SIGN_UP" | "FORGOT_PASSWORD";
export type StatusVehicle = "LIKE_NEW" | "OLD";
export type VehicleLine = "AUTO" | "MANUAL";
export type WarrantyPolicy = "GENUINE" | "EXPIRED";
export type Fuel = "SELF" | "PROVIDE";

type District = {
  code: string;
  image: string;
  lat: number;
  long: number;
  name: string;
  name_with_type: string;
  parent_code: string;
  path_with_type: string;
  _id: string;
};

type Payload = {
  type_rental: TypeRental[];
  house_type: HouseType[];
  vehicle_type: VehicleType[];
  gender: Gender[];
  otp_type: OtpType[];
  districts: District[];
};

type MasterDataContextType = {
  state: Payload;
  dispatch: React.Dispatch<MasterDataActionType>;
};

//* actions define
export enum MasterDataAction {
  setMasterData,
  setDistrict,
}

type SetMasterData = {
  type: MasterDataAction.setMasterData;
  payload: IAdmin<Payload>;
};

type SetDistrict = {
  type: MasterDataAction.setDistrict;
  payload: { districts: District[] };
};

type MasterDataActionType = SetMasterData | SetDistrict;

const initialState: Payload = {
  type_rental: [],
  house_type: [],
  vehicle_type: [],
  gender: [],
  otp_type: [],
  districts: [],
};

//* context define
const MasterDataContext = createContext<MasterDataContextType>({
  state: initialState,
  dispatch: () => null,
});

//* reducer define
const reducer = (state: Payload, action: MasterDataActionType) => {
  switch (action.type) {
    case MasterDataAction.setMasterData:
      return { ...state, ...action.payload };
    case MasterDataAction.setDistrict:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const MasterDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <MasterDataContext.Provider value={value}>
      {children}
    </MasterDataContext.Provider>
  );
};

export const useMasterData = () => useContext(MasterDataContext);
