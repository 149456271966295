import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

import {
  Button,
  Modal,
  Space,
  Table as TableAnt,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from "antd";
// import { Button } from "components";
import useSearch from "hooks/useSearch";
import { useRef, useState } from "react";

interface TableProps<T> {
  columns: any;
  dataSource: any;
  pagination?: TablePaginationConfig;
  hidePagination?: boolean;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  onDetail?: (id: string) => void;
  moreFunction?: {
    text: string;
    fn: (e: T) => void;
  };
  readonlyDelete?: boolean;
  readonlyUpdate?: boolean;
  readonlyDetail?: boolean;
  keyEdit?: string;
  width?: number;
}

const { Text } = Typography;

export const Table = <T,>({
  columns,
  dataSource,
  pagination,
  hidePagination,
  width,
  onDelete,
  onEdit,
  onDetail,
  moreFunction,
  readonlyDelete,
  readonlyUpdate,
  readonlyDetail,
  keyEdit = "id",
}: TableProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const keyRef = useRef<string>();
  const { params, setPage } = useSearch();

  return (
    <>
      <Modal
        title="Thông báo"
        open={isModalOpen}
        footer={[
          <Button key="back" onClick={() => setIsModalOpen(false)} autoFocus>
            Hủy
          </Button>,
          <Button
            key="submit"
            type="dashed"
            loading={false}
            className="text-[#F97162]"
            onClick={() => {
              if (keyRef.current && onDelete) {
                onDelete(keyRef.current);
                setIsModalOpen(false);
              }
            }}
          >
            Xóa
          </Button>,
        ]}
        onCancel={() => setIsModalOpen(false)}
      >
        <Text>Bạn có muốn xoá phần tử này không?</Text>
      </Modal>
      <div style={{ overflow: "auto" }}>
        <TableAnt
          columns={
            onDelete || onEdit || moreFunction
              ? [
                  ...columns,
                  {
                    title: "Chức năng",
                    key: "action",
                    render: (_, record) => {
                      return (
                        <Space size="small">
                          {moreFunction && (
                            <Button
                              type="primary"
                              ghost
                              onClick={() => {
                                moreFunction.fn(_);
                              }}
                              shape="circle"
                              disabled={readonlyUpdate}
                            >
                              {moreFunction.text}
                            </Button>
                          )}
                          {onDetail && (
                            <Tooltip title="Xem chi tiết">
                              <Button
                                className="flex justify-center items-center"
                                onClick={() => {
                                  onDetail(record[keyEdit]);
                                }}
                                icon={
                                  <EyeOutlined
                                    style={{ alignSelf: "center" }}
                                  />
                                }
                                shape="circle"
                                disabled={readonlyDetail}
                              />
                            </Tooltip>
                          )}
                          {onEdit && (
                            <Tooltip title="Chỉnh sửa">
                              <Button
                                className="flex justify-center items-center"
                                onClick={() =>
                                  onEdit(record[keyEdit] as string)
                                }
                                icon={<EditOutlined />}
                                shape="circle"
                                disabled={readonlyUpdate}
                              ></Button>
                            </Tooltip>
                          )}
                          {onDelete && (
                            <Tooltip title="Xóa">
                              <Button
                                className="flex items-center justify-center"
                                onClick={() => {
                                  keyRef.current = record.id?.toString();
                                  setIsModalOpen(true);
                                }}
                                danger
                                icon={<DeleteOutlined />}
                                shape="circle"
                                disabled={readonlyDelete}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      );
                    },
                  },
                ]
              : columns
          }
          dataSource={dataSource}
          scroll={{ x: width || 1300 }}
          pagination={
            hidePagination
              ? false
              : {
                  showLessItems: true,
                  showSizeChanger: true,
                  defaultPageSize: 10,
                  current: params.page,
                  ...pagination,
                  onChange: (page) => {
                    setPage(page);
                  },
                }
          }
        />
      </div>
    </>
  );
};
