/** @format */

import { Layout, Menu } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { Text } from "components";
import { PATHS, ROUTES } from "constants/routes";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

const menuSidebar = ROUTES.filter((i) => i.root);

const Sidebar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const getRootPath = () => {
    let splitParts = location.pathname.split("/").filter((path) => path);
    return splitParts.length > 0 ? "/" + splitParts[0] : PATHS.home;
  };

  const [current, setCurrent] = useState(getRootPath());

  const handleClickMenu: MenuItemType["onClick"] = useCallback(
    (item: any) => {
      const url = item?.keyPath?.reverse()?.join("/");
      navigate(url);
    },
    [navigate]
  );

  useEffect(() => {
    if (location) {
      let newCurrent = getRootPath();
      if (current !== newCurrent) {
        setCurrent(newCurrent);
      }
    }
  }, [location, current]);

  const menuItems = useMemo(() => {
    return menuSidebar?.map((menu) => {
      const Icon = menu.icon || undefined;
      return {
        key: `${menu.href}`,
        label: <Text useI18n>{menu?.title}</Text>,
        icon: Icon && <Icon />,
      };
    });
  }, []);

  return (
    <Layout.Sider
      width={305}
      theme="light"
      className="layout-sider"
      collapsed={false}
    >
      <Menu
        style={{ borderInlineEnd: "none" }}
        selectedKeys={[current]}
        mode="inline"
        theme="light"
        onClick={handleClickMenu}
        items={menuItems}
        className="menu-sider"
      />
    </Layout.Sider>
  );
};

export default memo(Sidebar);
