import {
  Input as InputAnt,
  InputNumber as InputNumberAntd,
  InputNumberProps,
  InputProps,
} from "antd";
import { valueType } from "antd/es/statistic/utils";
import { PropsWithChildren } from "react";

export const integrateInputClassname = (className?: string) =>
  `h-12 ${className}`;

export const Input = (props: InputProps) => (
  <InputAnt {...props} className={integrateInputClassname(props.className)} />
);

export function InputNumber({
  children,
  onChange,
  ...props
}: PropsWithChildren<InputNumberProps>) {
  const onChangeNumber = (event: valueType | null) => {
    const val = event?.toString().replace(/\D+/g, "");
    if (onChange) onChange(Number(val || 0));
  };

  return (
    <InputNumberAntd
      {...props}
      type="number"
      className={
        "flex items-center " + integrateInputClassname(props.className)
      }
      onChange={onChangeNumber}
    />
  );
}

export function InputCurrency({
  ...props
}: PropsWithChildren<InputNumberProps>) {
  return (
    <InputNumberAntd
      {...props}
      formatter={(value: string | number | undefined) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={(value: string | undefined) => value!.replace(/\$\s?|(,*)/g, "")}
      className={"flex items-center " + integrateInputClassname(props.className)}
    />
  );
}
