/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRegionDto } from '../models/CreateRegionDto';
import type { OrderBy } from '../models/OrderBy';
import type { UpdateRegionDto } from '../models/UpdateRegionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminRegionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create region
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerCreate(
        requestBody: CreateRegionDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/region',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all region
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @param name
     * @param countryId
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerGetAll(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
        name?: string,
        countryId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/region',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'name': name,
                'country_id': countryId,
            },
        });
    }

    /**
     * Get search region
     * @param search
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerSearch(
        search: string,
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/region/search',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
                'search': search,
            },
        });
    }

    /**
     * Get detail region
     * @param id
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerGetDetail(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/region/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update region
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerUpdate(
        id: number,
        requestBody: UpdateRegionDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/region/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete region
     * @param id
     * @returns any
     * @throws ApiError
     */
    public regionAdminControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/region/{id}',
            path: {
                'id': id,
            },
        });
    }

}
