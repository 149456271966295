/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateRoleDto = {
    name?: string;
    permissions?: Array<'admin.get_list' | 'admin.get_detail' | 'admin.create' | 'admin.update' | 'admin.delete' | 'user.get_list' | 'user.get_detail' | 'user.create' | 'user.update' | 'user.delete' | 'role.get_list' | 'role.get_detail' | 'role.create' | 'role.update' | 'role.delete' | 'country.get_list' | 'country.get_detail' | 'country.create' | 'country.update' | 'country.delete' | 'region.get_list' | 'region.get_detail' | 'region.create' | 'region.update' | 'region.delete' | 'driver_group.get_list' | 'driver_group.get_detail' | 'driver_group.create' | 'driver_group.update' | 'driver_group.delete' | 'sector.get_list' | 'sector.get_detail' | 'sector.create' | 'sector.update' | 'sector.delete' | 'product.get_list' | 'product.get_detail' | 'product.create' | 'product.update' | 'product.delete' | 'product.import' | 'product.export' | 'fee.get_list' | 'fee.get_detail' | 'fee.create' | 'fee.update' | 'fee.delete' | 'order.get_list' | 'order.get_detail' | 'order.create' | 'order.update' | 'order.delete' | 'partner_group.get_list' | 'partner_group.get_detail' | 'partner_group.create' | 'partner_group.update' | 'partner_group.delete'>;
    type?: UpdateRoleDto.type;
};

export namespace UpdateRoleDto {

    export enum type {
        ADMIN = 'admin',
        STAFF = 'staff',
        PARTNER = 'partner',
    }


}

