/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateChatForUserDto } from '../models/CreateChatForUserDto';
import type { OrderBy } from '../models/OrderBy';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserChatService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all message
     * @param limit
     * @param page
     * @param orderBy
     * @param sort
     * @returns any
     * @throws ApiError
     */
    public chatControllerGetListChatForUser(
        limit?: string,
        page?: string,
        orderBy?: string,
        sort?: OrderBy,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/user',
            query: {
                'limit': limit,
                'page': page,
                'order_by': orderBy,
                'sort': sort,
            },
        });
    }

    /**
     * Create new message
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public chatControllerCreateForUser(
        requestBody: CreateChatForUserDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/chat/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
